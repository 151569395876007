import React, { useEffect } from "react";
import { withDashboard } from "@wix/dashboard-react";
import * as Icons from "@wix/wix-ui-icons-common";

import {
  Box,
  Card,
  Loader,
  Page,
  TableToolbar,
  WixDesignSystemProvider,
  Table,
  Badge,
  Text,
  Divider,
  Image,
  Popover,
  TextButton,
  Button,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";
import { orders } from "@wix/ecom";
import { createClient } from "@wix/sdk";
import { dashboard } from "@wix/dashboard";
import { relative } from "path";
import { useTranslation } from "react-i18next";
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [openedOrderId, setOpenedOrderId] = React.useState("");
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({} as any);
  const [ordersList, setOrders] = React.useState([] as any);
  const [client, setClient] = React.useState(null as any);
  const { t } = useTranslation();
  const siteId = instanceData?.site?.siteId;

  const baseUrl = "https://certifiedcode.wixsite.com/order-viewer/_functions";

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }

    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/order-viewer/_functions", // Replace with your API base URL
      applicationID: "22750b4c-9cea-4d26-8143-a84ce2420408", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    fetch(`${baseUrl}/orders`, {
      headers: {
        Authorization: instance!,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOrders(data);
      });
  }, []);

  useEffect(() => {
    console.log(openedOrderId);
  }, [openedOrderId]);

  const columns = [
    {
      title: "Date Created",
      render: (row: orders.Order) =>
        // @ts-ignore
        `${new Date(row.createdDate!)?.toLocaleString()}`,
    },
    {
      title: "Customer",
      render: (row: orders.Order) =>
        `${row.billingInfo?.contactDetails?.firstName} ${row.billingInfo?.contactDetails?.lastName}`,
    },
    {
      title: "Payment",
      render: (row: orders.Order) => (
        // @ts-ignore
        <Badge size="small" {...getPaymentStatusBadgeProps(row)}>
          {getPaymentStatusText(row)}
        </Badge>
      ),
    },
    {
      title: "Fulfillment",
      render: (row: orders.Order) => (
        // @ts-ignore
        <Badge size="small" {...getFulfillmentStatusBadgeProps(row)}>
          {getFulfillmentStatusText(row)}
        </Badge>
      ),
    },
    {
      title: "Total",
      render: (row: orders.Order) => row.priceSummary?.total?.formattedAmount!,
    },
    {
      title: "Items",
      render: (row: orders.Order) => {
        const quantity = row.lineItems?.reduce(
          (sum, lineItem) =>
            (sum += lineItem?.quantity ?? /* istanbul ignore next */ 0),
          0
        );

        return (
          <Popover
            // @ts-ignore
            shown={openedOrderId === row.id}
            animate
            appendTo="viewport"
            placement="bottom-end"
            onClickOutside={() => {
              setOpenedOrderId("");
            }}
          >
            <Popover.Element>
              <TextButton
                size="small"
                suffixIcon={
                  // @ts-ignore
                  openedOrderId === row.id ? (
                    <Icons.ChevronUp />
                  ) : (
                    <Icons.ChevronDown />
                  )
                }
                style={{ height: "36px", minWidth: "75px" }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  setOpenedOrderId(
                    // @ts-ignore
                    openedOrderId === row.id ? "" : row.id!
                  );
                }}
              >
                {quantity}
              </TextButton>
            </Popover.Element>
            <Popover.Content>
              <Box
                direction="vertical"
                width="400px"
                maxHeight="400px"
                overflowY="scroll"
              >
                <Box direction="vertical">
                  <Box paddingLeft="24px" paddingTop="12px" paddingBottom="6px">
                    <Text weight="bold">Items ({quantity})</Text>
                  </Box>
                  {row.lineItems?.map((lineItem, i) => (
                    <React.Fragment key={i}>
                      <Box padding="12px 18px" gap="SP2">
                        <Box flexShrink={0}>
                          <Image
                            // @ts-ignore
                            src={lineItem?.image?.url}
                            height="48px"
                            width="48px"
                          />
                        </Box>
                        <Box gap="SP4" flexGrow={1}>
                          <Box direction="vertical" flexGrow={1}>
                            <Text weight="bold" size="small">
                              {lineItem.productName?.original}
                            </Text>
                            {lineItem.descriptionLines!.map((line, index) => (
                              <Text size="small" secondary key={index}>
                                {line.plainText?.original}
                              </Text>
                            ))}
                          </Box>
                          <Box flexShrink={0} align="right">
                            {quantity && (
                              <Text weight="thin" size="medium" skin="disabled">
                                X {quantity}
                              </Text>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      {i < row.lineItems!.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Popover.Content>
          </Popover>
        );
      },
    },
    {
      title: "",
      render: (row: orders.Order) =>
        !isUpgraded ? (
          <Button
            size="small"
            skin="premium"
            prefixIcon={<Icons.PremiumFilled />}
            as="a"
            href={basePlatform.getUpgradeUrl()}
            target="_blank"
          >
            View Order
          </Button>
        ) : (
          <Button
            size="small"
            skin="inverted"
            onClick={() => {
              window.open(
                // @ts-ignore
                `https://manage.wix.com/dashboard/${siteId}/store/orders/order/${row.id}`
              );
            }}
          >
            View Order
          </Button>
        ),
    },
  ];

  const OrderStatus = orders.OrderStatus;
  const PaymentStatus = orders.PaymentStatus;
  const FulfillmentStatus = orders.FulfillmentStatus;

  function getPaymentStatusText(order: orders.Order) {
    if (order.status === OrderStatus.CANCELED) {
      return "Canceled";
    }
    switch (order.paymentStatus) {
      case PaymentStatus.PAID:
        return t("paid");
      case PaymentStatus.NOT_PAID:
        return t("not-paid");
      case PaymentStatus.PARTIALLY_PAID:
        return t("partially-paid");
      case PaymentStatus.PARTIALLY_REFUNDED:
        return t("partially-refunded");
      case PaymentStatus.FULLY_REFUNDED:
        return t("fully-refunded");
      default:
        return t("unknown");
    }
  }

  function getFulfillmentStatusText(order: orders.Order) {
    if (order.status === OrderStatus.CANCELED) {
      return "Canceled";
    }
    switch (order.fulfillmentStatus) {
      case FulfillmentStatus.FULFILLED:
        return t("fullfilled");
      case FulfillmentStatus.NOT_FULFILLED:
        return t("not-fullfilled");
      case FulfillmentStatus.PARTIALLY_FULFILLED:
        return t("partially-fullfilled");
      default:
        return t("unknown");
    }
  }

  function getFulfillmentStatusBadgeProps(order: orders.Order) {
    if (order.status === OrderStatus.CANCELED) {
      return {
        skin: "neutralLight",
        type: "outlined",
      };
    }
    switch (order.fulfillmentStatus) {
      case FulfillmentStatus.FULFILLED:
        return {
          skin: "neutralSuccess",
          type: "solid",
        };
      case FulfillmentStatus.NOT_FULFILLED:
        return {
          skin: "neutralDanger",
          type: "solid",
        };
      case FulfillmentStatus.PARTIALLY_FULFILLED:
      default:
        return {
          skin: "warningLight",
          type: "solid",
        };
    }
  }

  function getPaymentStatusBadgeProps(order: orders.Order) {
    if (order.status === OrderStatus.CANCELED) {
      return {
        type: "outlined",
        skin: "neutralLight",
      };
    }
    switch (order.paymentStatus) {
      case PaymentStatus.PAID:
        return {
          skin: "neutralSuccess",
        };
      case PaymentStatus.NOT_PAID:
        return {
          skin: "neutralDanger",
        };
      case PaymentStatus.PARTIALLY_PAID:
        return {
          skin: "warningLight",
        };
      case PaymentStatus.PARTIALLY_REFUNDED:
      case PaymentStatus.FULLY_REFUNDED:
        return {
          type: "outlined",
          skin: "neutralLight",
        };
      default:
        return {
          skin: "neutralStandard",
        };
    }
  }

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t("loading")} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page>
        <Page.Header
          title={t("title")}
          subtitle={t("subtitle")}
          actionsBar={
            !isUpgraded ? <Button
              prefixIcon={<Icons.PremiumFilled />}
              as="a"
              skin="premium"
              href={basePlatform.getUpgradeUrl()}
              target="_blank"
            >
              {t("upgrade-button")}
            </Button> : null
          }
        />
        <Page.Content>
          {!ordersList.orders && (
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t("loading")} />
            </Box>
          )}
          {ordersList.orders && (
            <Card hideOverflow>
              <Table
                data={
                  ordersList?.orders?.filter(
                    (v: orders.Order) => v.status === "INITIALIZED"
                  ) || []
                }
                columns={columns}
              >
                <TableToolbar>
                  <TableToolbar.Title>
                    {t("potential-order")}
                  </TableToolbar.Title>
                </TableToolbar>
                {/* <Table.SubToolbar>
                <TableToolbar.Label>Subtoolbar</TableToolbar.Label>
              </Table.SubToolbar> */}
                <Table.Content />
              </Table>
            </Card>
          )}
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
